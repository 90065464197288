export const readers = {
  AZURE: 'azure',
  EDP_SP_B:'edp-sp-b',
  COPEL_B:'copel-b',
  COPEL:'copel',
  ENERGISA_B:'energisa-b',
  ENEL:'enel',
  MANUAL: 'automatico',
  CEMIG_DEMONSTRATIVO:'cemig-demonstrativo',
  COSERN_DEMONSTRATIVO:'cosern',
  CELESC_DEMONSTRATIVO:'celesc',
  DEMONSTRATIVO_GENERICO:'generic',
  DEFAULT:'default'
} as const;

export const distributors = {
  ENERGISA: 'energisa',
  EDP: 'edp',
  NEOENERGIA:'neoenergia',
  CEMIG:'cemig',
} as const;

export type Distributor = typeof distributors[keyof typeof distributors]

export type Reader = typeof readers[keyof typeof readers];

export type DistributorUrls = {
  name: string;
  url: string;
  description?:string
};

type AzureDistributors = 'energisa' | 'edp' | 'neoenergia' | 'cemig';

export type InvoicesUrlPerDistributor = {
  [readers.AZURE]: Record<AzureDistributors, DistributorUrls[]>;
  [readers.MANUAL]: DistributorUrls[];
  [readers.EDP_SP_B]: DistributorUrls[];
  [readers.COPEL_B]: DistributorUrls[];
  [readers.COPEL]: DistributorUrls[];
  [readers.ENERGISA_B]: DistributorUrls[];
  [readers.ENEL]: DistributorUrls[];
  [readers.CEMIG_DEMONSTRATIVO]: DistributorUrls[];
  [readers.COSERN_DEMONSTRATIVO]: DistributorUrls[];
  [readers.CEMIG_DEMONSTRATIVO]: DistributorUrls[];
  [readers.CELESC_DEMONSTRATIVO]: DistributorUrls[];
  [readers.DEMONSTRATIVO_GENERICO]: DistributorUrls[];
  [readers.DEFAULT]: DistributorUrls[];

};

export const invoicesUrlPerDistributor: InvoicesUrlPerDistributor = {
  [readers.AZURE]: {
    'energisa': [
      {
        name: 'Energisa MT',
        url: 'https://api.labs-lumi.com.br/faturas/download/7a8162d6-a277-4acd-b9d7-5c22996f6633',
      },
      {
        name: 'Energisa MS',
        url: 'https://api.labs-lumi.com.br/faturas/download/d0939b1f-15e7-4e21-bb36-3f6ce008db74',
      },
      {
        name: 'Energisa PB',
        url: 'https://api.labs-lumi.com.br/faturas/download/d0939b1f-15e7-4e21-bb36-3f6ce008db74',
      },
      {
        name: 'Energisa TO',
        url: 'https://api.labs-lumi.com.br/faturas/download/2d76b5b9-0a30-4701-9bcf-45ad6aa3fe83',
      },
      {
        name: 'Energisa RO',
        url: 'https://api.labs-lumi.com.br/faturas/download/38dbe837-f8bf-476e-89e2-dad474af3e1f',
      },
      {
        name: 'Energisa NF',
        url: 'https://api.labs-lumi.com.br/faturas/download/db59bdd3-2385-4c14-84fd-2084c3ed0342',
      },
    ],
    'edp': [],
    'neoenergia': [],
    'cemig': [
      {
        name: 'Cemig',
        url: 'https://api.labs-lumi.com.br/faturas/download/e2253cfc-5eda-49a7-835d-08661cd53be5',
      },
    ],
  },
  [readers.MANUAL]: [
    {
      name: 'Cemig',
      url: 'https://api.labs-lumi.com.br/faturas/download/e2253cfc-5eda-49a7-835d-08661cd53be5',
    },
    {
      name:'Equatorial GO',
      url:'https://api.labs-lumi.com.br/faturas/download/0ebfe5e0-65f8-4639-a6f5-be8056a0e8d1'
    }
  ],
  [readers.EDP_SP_B]: [],
  [readers.COPEL_B]: [],
  [readers.ENERGISA_B]: [],
  [readers.COPEL]: [],
  [readers.ENEL]: [],
  [readers.CEMIG_DEMONSTRATIVO]: [],
  [readers.CELESC_DEMONSTRATIVO]: [],
  [readers.COSERN_DEMONSTRATIVO]: [],
  [readers.DEMONSTRATIVO_GENERICO]: [],
  [readers.DEFAULT]: [],
};
